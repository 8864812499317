import React, { FC } from 'react';
import useScreenRecognition from 'hooks/useScreenRecognition';
import classnames from 'classnames';
import './QuizProgress.scss';

const QuizProgress: FC<QuizTypes.IQuizProgressBar> = ({
  currentStep,
  progressBar,
  progressBarTitle,
  stepPrefix,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();
  const progress = Math.min((currentStep / progressBar.length) * 100, 100);

  return (
    <div className="quiz-progress" data-testid="quiz-progress">

      {isMobile || isTablet ? (
        <>
          {progressBarTitle ? (
            <h1 className="quiz-progress__title mobile-view_title">{progressBarTitle}</h1>
          ) : null}
          <div className="quiz-progress__step--mobile">
            {`${stepPrefix} ${currentStep}/${progressBar?.length}`}
          </div>
          <div className="quiz-progress__track quiz-progress__track--mobile">
            <div className="quiz-progress__track-thumb" style={{ width: `${progress}%` }} />
          </div>
        </>
      ) : null}
      {isDesktop ? (
        <>
          {progressBarTitle ? (
            <h1 className="quiz-progress__title">{progressBarTitle}</h1>
          ) : null}
          <div className="quiz-progress__list-wrap">
            <ul className="quiz-progress__list">
              {progressBar.map(({ properties: { title } }, idx) => (
                <li key={title} className={classnames({ 'active-step': idx + 1 === currentStep })}>
                  <div className="quiz-progress__step">{`${stepPrefix} ${idx + 1}`}</div>
                  <div className="quiz-progress__step-name">{title}</div>
                </li>
              ))}
            </ul>
            <div className="quiz-progress__track quiz-progress__track--desktop">
              <div className="quiz-progress__track-thumb" style={{ height: `${progress}%` }} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default QuizProgress;
